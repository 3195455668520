import { ReactNode, useEffect, useState } from 'react'
import type { LoaderFunction, MetaFunction } from 'react-router'
import { AboutUs } from '~/components/about-us'
import { Footer } from '~/components/footer'
import { Header } from '~/components/header'
import { Navbar } from '~/components/navbar'
import { Pricing } from '~/components/pricing'
import { Sports } from '~/components/sports'
import { Team } from '~/components/team'
import '../style.css'

export function ClientOnly({ children }: { children: ReactNode }) {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])
  return mounted ? <>{children}</> : null
}

export const meta: MetaFunction = () => {
  return [
    { title: 'مآریان | MARIAN by Sajjad Shokri' },
    {
      name: 'description',
      content: 'مجموعه ورزشی مآریان',
    },
  ]
}

export const loader: LoaderFunction = async ({ context }) => {
  // const db = drizzle(context.cloudflare.env.DB)
  // const usersResult = await db.select().from(users)
  // return data(usersResult)
}

export default function Index() {
  return (
    <div className="main-body">
      <Navbar />
      <Header />
      <AboutUs />
      <Team />
      <Sports />
      <Pricing />
      <Footer />
    </div>
  )
}
