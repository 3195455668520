import { motion } from 'framer-motion'

const initial = { opacity: 0, y: 40 }
const whileInView = { opacity: 1, y: 0 }
const viewport = { once: true }
const transition = { duration: 0.4, delay: 0.3 }

const sports = [
  {
    title: 'بوکس',
    description:
      'بوکس (Boxing) بعنوان یک ورزش رزمی به آمادگی بدنی کمک کرده و سبب تقویت قدرت، سرعت، چابکی، هماهنگی دست و چشم و استقامت در بوکسور ها می شود و تقریباً عضلات تمام بدن زمان مشت زدن،گارد گرفتن،جا خالی دادن و جهش ها به کار گرفته می شوند. در این رشته استرس، عصبانیت و تمام احساسات منفی به معنای واقعی کلمه پس از چندین راند روی کیسه سنگین یا بعد از یک جلسه مبارزه ناپدید می‌شوند.',
    img: '/images/box.jpeg',
  },
  {
    title: 'فانکشنال فیتنس',
    description:
      'هدف تمرینات فانکشنال تناسب اندام، افزایش توانایی فرد برای انجام فعالیت های روزمره و ورزشی است.تمرینات فانکشنال با شبیه‌سازیِ حرکات مشترکی که ممکن است در خانه، محل کار یا ورزش انجام دهید، عضلات شما تمرین داده می‌شود و آن‌ها برای توانایی و قدرتمندی کارهای روزمره آماده می‌شوند. اصول تمرینات فانکشنال این است که عضلات بدن به جای کار کردن به صورت ایزوله به صورت هماهنگ با یکدیگر و به عنوان یک واحد عمل کنند. این کار نتیجه تلاشی جمعی بین سیستم های اعصاب،قلب و عروق،تنفس و عضلانی–اسکلتی می باشد که به فرد امکان داشتن حرکتی روان را می دهند.',
    img: '/images/fankshenal.jpg',
  },
  {
    title: 'بدنسازی',
    description:
      'فیتنس یکی از پرطرفدارترین رشته‌های بدنسازی است که بر تناسب اندام، افزایش توان بدنی، فرم‌دهی و تفکیک عضلات بدن تمرکز دارد. در فیتنس با این‌که عضله‌ سازی انجام می‌شود، اما تمرکز بر کار با وزنه‌های سنگین و عضله ‌سازی نیست. هدف این رشته ورزشی تناسب اندام و درصد چربی کم است. فیتنس شامل تمریناتی است که مهره ی کامل کننده یک بدن اماده است. مزیت های آن شامل: افزایش نسبی قدرت بدنی، بهبود عملکرد ورزشی، افزایش چربی سوزی بدن، کاهش استرس و اضطراب، تقویت بافت های هم بند و رباط ها و مقاوم سازی مفاصل می شود.',
    img: '/images/bodybuilding.jpg',
  },
  {
    title: 'دفاع شخصی',
    description:
      'دفاع شخصی عملی است که برای محافظت از خود در برابر حملات، با استفاده از نیروی فیزیکی انجام می دهیم. برای دفاعی موثر در برابر حملات متنوع لازم است تا انواع گوناگون حملات و تکنیک های موثر برای دفاع در برابر این گونه حملات را با شناسایی نقاط ضعف و قوت و محدودیت ها تجزیه و تحلیل کنیم و از نیروی حریف علیه خودش استفاده کنیم. دفاع شخصی خود به دفاع فیزیکی-دفاع بدون سلاح-دفاع با استفاده از سلاح تقسیم میشود. در هاپكيدو به عنوان علمی و کاربردی ترین رشته ی دفاع شخصی در هر جلسه تمريني شاهد بخش هاي متنوع و گوناگون سازندگي اعم از بدنسازي، ضربات دست و پا، اطلاع از نقاط حساس عصبی، تكنيك هاي قفل مفصل، انواع فنون گلاویزی و پرتابی، کار با انواع سلاح سرد (نانچیکو، تونفا، چوب، شمشیر و…) و غيره مي باشد.',
    img: '/images/self-defense.jpeg',
  },
]

export const Sports = () => {
  return (
    <section className="py-[120px]" id="M3">
      <div className="container flex flex-col gap-16 md:gap-16">
        <motion.h2 initial={initial} whileInView={whileInView} viewport={viewport} transition={transition} className="h2-subtitle">
          رشته های ورزشی مجموعه ما
        </motion.h2>
        <div className="flex flex-col gap-14">
          {sports.map(sport => {
            return (
              <motion.div
                initial={initial}
                whileInView={whileInView}
                viewport={viewport}
                transition={transition}
                className="flex flex-col md:flex-row md:odd:flex-row-reverse gap-8 wow animate__fadeInUp"
                key={sport.title}
              >
                <div className="basis-1/2 flex flex-col gap-5 justify-center">
                  <h4 className="font-semibold text-xl md:text-2xl text-white">{sport.title}</h4>
                  <img src={sport.img} alt={sport.title} className="md:hidden rounded-md" />
                  <p className="text-gray-300 leading-relaxed text-justify">{sport.description}</p>
                </div>
                <div className="basis-1/2 hidden md:block">
                  <img src={sport.img} alt={sport.title} className="rounded-md" />
                </div>
              </motion.div>
            )
          })}
        </div>
      </div>
    </section>
  )
}
